import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { checkToken } from "../../utils/helper"
import { useSelector } from "react-redux"
import Toaster from "../common/Toaster"
import Header from "../guest/Header"
import Footer from "../guest/Footer"
import { Box } from "@mui/material"
import { useEffect } from "react"

function Layout() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { user } = useSelector(state => state.authSlice)

  useEffect(() => {
    if (!checkToken()) {
      return navigate("/login")
    }

    if (pathname !== "/admin/login") {
      if (user?.role !== "user") { return navigate("/") }
    }

    // eslint-disable-next-line
  }, [])

  return (
    <Box
      sx={{
        display: "grid",
        minHeight: "100vh",
        gridTemplateRows: "auto 1fr auto"
      }}
    >
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
      <Toaster />
    </Box>
  )
}

export default Layout