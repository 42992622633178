import MuiIcons from "../../assets/js/muiIcons";

export const data = [
  {
    path: "/admin/users",
    title: "total users",
    numbers: "totalUsers",
    icon: <MuiIcons.GroupRounded sx={{ fontSize: "32px" }} />
  },
  {
    title: "total request",
    numbers: "totalRequest",
    path: "/admin/all-request",
    icon: <MuiIcons.ForumRounded sx={{ fontSize: "32px" }} />
  },
  {
    title: "pending request",
    numbers: "pendingRequest",
    path: "/admin/all-request",
    icon: <MuiIcons.MarkChatUnreadRounded sx={{ fontSize: "32px" }} />
  },
  {
    title: "approved request",
    numbers: "approvedRequest",
    path: "/admin/all-request",
    icon: <MuiIcons.MarkChatReadRounded sx={{ fontSize: "32px" }} />
  },
  {
    title: "rejected request",
    numbers: "rejectedRequest",
    path: "/admin/all-request",
    icon: <MuiIcons.AnnouncementRounded sx={{ fontSize: "32px" }} />
  },
]