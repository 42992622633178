import { adminLoginReq } from "../../redux/adminSlices/admin.slice"
import { toastError } from "../../redux/guestSlices/other.slice"
import CustomInput from "../../components/common/CustomInput"
import { useDispatch, useSelector } from "react-redux"
import { checkAdminToken } from "../../utils/helper"
import MuiIcons from "../../assets/js/muiIcons"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import {
  Box, Button, CircularProgress, Container,
  Grid2 as Grid, ListItem, ListItemIcon,
  ListItemText, Paper, styled,
} from "@mui/material"

const Paragraph = styled("p")(() => ({}))
function Login() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isLogin = checkAdminToken(dispatch)
  const { admin } = useSelector(state => state.adminSlice)

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)
    const formData = Object.fromEntries(form)
    if ([formData?.email, formData?.password]?.some(
      (field) => field?.trim() === ""
    )) {
      return dispatch(toastError("Please fill all required field!"))
    }
    dispatch(adminLoginReq(formData))
  }

  useEffect(() => {
    if (isLogin) { return navigate("/admin/dashboard") }

    // eslint-disable-next-line
  }, [])

  return (
    <Box component="section" sx={{ bgcolor: "#f0f9ff", height: "100%" }}>
      <Container sx={{ height: "100%" }}>
        <Box sx={{ display: "grid", placeContent: "center", height: "100%", py: 4 }}>
          <Box
            sx={{
              p: 3,
              maxWidth: "450px",
              borderRadius: "8px",
              boxShadow: "0px 0px 12px 0px #00000024"
            }}
            component={Paper}
          >
            <ListItem sx={{ justifyContent: "center", mb: 2 }}>
              <ListItemIcon sx={{ minWidth: "auto" }}>
                <MuiIcons.AdminPanelSettingsRounded
                  sx={{ fontSize: "40px", mr: 1, color: "#110e24" }} />
              </ListItemIcon>
              <ListItemText sx={{ flexGrow: "unset", m: "0" }}>
                <Paragraph
                  sx={{
                    color: "#110e24",
                    fontSize: "30px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                  }}
                >Admin Login
                </Paragraph>
              </ListItemText>
            </ListItem>
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid size={12}>
                  <CustomInput
                    name="email"
                    type="email"
                    label="email"
                  />
                </Grid>
                <Grid size={12}>
                  <CustomInput
                    name="password"
                    type="password"
                    label="password"
                  />
                </Grid>
                <Grid size={12}>
                  <Button
                    sx={{
                      width: "100%",
                      color: "white",
                      fontSize: "16px",
                      bgcolor: "#00cada",
                      fontWeight: "400",
                      boxShadow: "none",
                      fontFamily: "Poppins",
                      textTransform: "capitalize"
                    }}
                    type="submit"
                  >Login
                    {admin?.loading &&
                      <CircularProgress
                        size={25}
                        thickness={5}
                        sx={{
                          ml: 1,
                          display: "flex",
                          color: "white"
                        }}
                      />
                    }
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Login