import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Toaster from "../common/Toaster"
import { Box } from "@mui/material"
import Sidebar from "./Sidebar"
import Header from "./Header"
import DialogContainer from "../common/DialogContainer"
import { checkToken } from "../../utils/helper"

function Layout() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [active, setActive] = useState(false)
  const { admin } = useSelector(state => state.adminSlice)

  const sidebarActive = () => { setActive(true) }
  const sidebarDeActive = () => { setActive(false) }

  useEffect(() => {
    // if (pathname !== "/admin/login") {
    //   if (admin?.role !== "admin") { return navigate("/") }
    // }
    // if (checkToken()) {
    //   console.log("here")
    // }

    // eslint-disable-next-line
  }, [])

  return (
    <Box
      sx={{
        display: "grid",
        minHeight: "100vh",
        gridTemplateRows: "auto 1fr auto"
      }}
    >
      {pathname !== "/admin/login" &&
        <Sidebar
          active={active}
          sidebarDeActive={sidebarDeActive}
        />
      }
      <Box
        sx={{
          display: "grid",
          minHeight: "100vh",
          bgcolor: "#ebffff",
          transitionDuration: "350ms",
          gridTemplateRows: "auto 1fr",
          ml: { laptop: pathname !== "/admin/login" ? "250px" : "0" },
        }}
        onClick={() => { active && sidebarDeActive() }}
      >
        {pathname !== "/admin/login" &&
          <Header
            active={active}
            sidebarActive={sidebarActive}
          />
        }
        {pathname === "/admin/login" &&
          <Box
          />
        }
        <main>
          <Outlet />
        </main>
      </Box>
      <Toaster />
      <DialogContainer />
    </Box>
  )
}

export default Layout