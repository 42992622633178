import SingleRequest from "../pages/admin/requests/SingleRequest"
import SingleContact from "../pages/admin/contacts/SingleContact"
import AllRequest from "../pages/admin/requests/AllRequest"
import AllContact from "../pages/admin/contacts/AllContact"
import SingleUser from "../pages/admin/users/SingleUser"
import AdminProfile from "../pages/admin/AdminProfile"
import Dashboard from "../pages/admin/Dashboard"
import Users from "../pages/admin/users/AllUser"
import Layout from "../components/admin/Layout"
import { Route } from "react-router-dom"
import Login from "../pages/admin/Login"

const AdminRoutes = (
  <Route path="/admin" element={<Layout />}>
    <Route path="login" element={<Login />} />
    <Route path="users" element={<Users />} />
    <Route path="dashboard" element={<Dashboard />} />
    <Route path="profile" element={<AdminProfile />} />
    <Route path="all-request" element={<AllRequest />} />
    <Route path="profile/:userId" element={<SingleUser />} />
    <Route path="request/:requestId" element={<SingleRequest />} />
    <Route path="all-contacts" element={<AllContact />} />
    <Route path="contact/:requestId" element={<SingleContact />} />
  </Route>
)

export default AdminRoutes