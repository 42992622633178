import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toastError, toastSuccess } from "../guestSlices/other.slice"
import { adminHeaders, BASEURI } from "../../utils/helper"
import axios from "axios"

// Get all users request
export const getAllUsersReq = createAsyncThunk("getAllUsersReq", ({ page, search }, { rejectWithValue, dispatch }) => {
  const params = {}
  if (page) { params.page = page }
  if (search) { params.search = search }
  try {
    const response = axios.get(`${BASEURI}/admin/all-users`, {
      params,
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Get single user request
export const getSingleUserReq = createAsyncThunk("getSingleUserReq", (id, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.get(`${BASEURI}/admin/get-user/${id}`, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        return res?.data?.user
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Delete user request
export const deleteUserReq = createAsyncThunk("deleteUserReq", (id, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.delete(`${BASEURI}/admin/delete/user/${id}`, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(toastSuccess(res?.data?.message))
        return res?.data
      } else {
        dispatch(toastSuccess(res?.data?.error))
        return res?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

// Toggle user status request
export const toggleUserStatusReq = createAsyncThunk("toggleUserStatusReq", ({ id, status }, { rejectWithValue, dispatch }) => {
  try {
    const response = axios.put(`${BASEURI}/admin/update-user-status/${id}`, { status }, {
      headers: adminHeaders()
    }).then((res) => {
      if (res?.data?.success) {
        dispatch(toastSuccess(res?.data?.message))
        return res?.data
      }
    }).catch((err) => {
      if (err?.response?.data?.error) {
        dispatch(toastError(err?.response?.data?.error))
      } else {
        dispatch(toastError("Something went wrong!"))
      }
      return rejectWithValue(err)
    })

    return response
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  users: {},
  singleUser: {},
}
const users = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get all users request
    builder.addCase(getAllUsersReq.pending, (state) => {
      state.users.allLoading = true
    })
    builder.addCase(getAllUsersReq.fulfilled, (state) => {
      delete state.users.allLoading
    })
    builder.addCase(getAllUsersReq.rejected, (state) => {
      delete state.users.allLoading
    })

    // Delete user request
    builder.addCase(deleteUserReq.pending, (state) => {
      state.users.delLoading = true
    })
    builder.addCase(deleteUserReq.fulfilled, (state) => {
      delete state.users.delLoading
    })
    builder.addCase(deleteUserReq.rejected, (state) => {
      delete state.users.delLoading
    })

    // Get single user request
    builder.addCase(getSingleUserReq.pending, (state) => {
      state.singleUser.loading = true
    })
    builder.addCase(getSingleUserReq.fulfilled, (state, { payload }) => {
      delete state.singleUser.loading
      state.singleUser = payload
    })
    builder.addCase(getSingleUserReq.rejected, (state) => {
      delete state.singleUser.loading
    })

    // Toggle user status request
    builder.addCase(toggleUserStatusReq.pending, (state) => {
      state.singleUser.sLoading = true
    })
    builder.addCase(toggleUserStatusReq.fulfilled, (state) => {
      delete state.singleUser.sLoading
    })
    builder.addCase(toggleUserStatusReq.rejected, (state) => {
      delete state.singleUser.sLoading
    })

    // Logout
    builder.addCase("Logout", () => initialState)
  }
})

export default users.reducer