import { getAllRequests } from "../../../redux/userSlices/request.slice"
import ImgWithSkel from "../../../components/common/ImgWithSkel"
import { useDispatch, useSelector } from "react-redux"
import { grey, red } from "@mui/material/colors"
import { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Box, Button, Chip, Container,
  Grid2 as Grid, Skeleton, Stack,
  Typography
} from "@mui/material"

function AllRequest() {
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  const { request } = useSelector(state => state.requestSlice)

  const getAllRequestsHandle = () => {
    dispatch(getAllRequests()
    ).unwrap().then((res) => {
      setData(res)
    })
  }

  useEffect(() => {
    getAllRequestsHandle()

    // eslint-disable-next-line
  }, [])

  return (
    <Box component="section" sx={{ p: { xs: 1, md: 3 } }}>
      <Container maxWidth="xxl">
        <Box sx={{ maxWidth: "1024px", mx: "auto" }}>
          <Box
            sx={{
              py: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Typography
              sx={{
                color: "#110e24",
                fontSize: "20px",
                fontWeight: "500",
                fontFamily: "Poppins",
              }}
            >Requests : {data?.totalRequest}
            </Typography>
            <Button
              sx={{
                fontSize: "16px",
                minWidth: "150px",
                fontWeight: "400",
                bgcolor: "#00cada",
                fontFamily: "Poppins",
                textTransform: "capitalize",
                boxShadow: "none !important"
              }}
              size="small"
              to="/user/upload"
              variant="contained"
              LinkComponent={Link}
            >new request
            </Button>
          </Box>
          <Grid container spacing={3}>
            {request?.loading ?
              new Array(3).fill()?.map((_, i) => (
                <Grid key={i} size={12}>
                  <Box
                    sx={{
                      p: 1,
                      gap: 1,
                      display: "flex",
                      borderRadius: "8px",
                      border: "1px dashed #00000042"
                    }}
                  >
                    <Skeleton animation="wave" sx={{ height: "150px", width: "200px", transform: "none" }} />
                    <Stack sx={{ flexGrow: "1" }} spacing={2}>
                      <Skeleton animation="wave" sx={{ height: "30px", width: "100%", transform: "none" }} />
                      <Skeleton animation="wave" sx={{ height: "15px", width: "20%", transform: "none" }} />
                    </Stack>
                  </Box>
                </Grid>
              )) :
              data?.allRequest?.length > 0 ?
                data?.allRequest?.map((request, i) => (
                  <Grid key={i} size={12}>
                    <Box
                      sx={{
                        p: 1,
                        gap: 1,
                        display: "flex",
                        cursor: "pointer",
                        borderRadius: "8px",
                        border: "1px dashed #00cada"
                      }}
                      component={Link}
                      to={`/user/request/${request?._id}`}
                    >
                      <ImgWithSkel
                        diamention={{
                          width: "200px",
                          height: "150px",
                          minWidth: "200px",
                        }}
                        src={request?.fileURL}
                      />
                      <Stack>
                        <Typography
                          sx={{
                            width: "100%",
                            fontSize: "14px",
                            color: "#110e24",
                            fontFamily: "Poppins",
                          }}
                          className="lineclamp"
                        >
                          {
                            request?.promptResponse?.split("\n\n")?.map((text, i) => (
                              <Fragment key={i}>{text}<br /></Fragment>
                            ))
                          }
                        </Typography>

                        <Box
                          sx={{
                            mt: "auto",
                            width: "100%",
                            fontSize: "14px",
                            color: "#110e24",
                            fontFamily: "Poppins",
                          }}
                          className="lineclamp"
                        >Status :&nbsp;
                          <Chip
                            sx={{
                              py: "2px",
                              px: "6px",
                              height: "auto",
                              bgcolor: request?.status === "rejected" ?
                                red[500] : request?.status === "pending" ?
                                  grey[500] : "#00cada",

                              "span": {
                                p: "0",
                                color: "white",
                                fontSize: "10px",
                                // lineHeight: "1.1",
                                fontFamily: "Poppins",
                                textTransform: "capitalize",
                              }
                            }}
                            size="small"
                            label={request?.status}
                          />
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                )) :
                <Grid size={12}>
                  <Box
                    sx={{
                      p: 3,
                      gap: 1,
                      display: "grid",
                      fontSize: "24px",
                      cursor: "pointer",
                      borderRadius: "8px",
                      fontFamily: "Poppins",
                      placeContent: "center",
                      border: "1px dashed #00cada"
                    }}
                  >No records found!
                  </Box>
                </Grid>
            }
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default AllRequest