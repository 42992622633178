import AllRequest from "../pages/user/request/AllRequest"
import Layout from "../components/user/Layout"
import SingleRequest from "../pages/user/request/SingleRequest"
import Upload from "../pages/user/Upload"
import { Route } from "react-router-dom"

const UserRoutes = (
  <Route path="/user" element={<Layout />}>
    <Route path="upload" element={<Upload />} />
    <Route path="all-request" element={<AllRequest />} />
    <Route path="request/:requestId" element={<SingleRequest />} />
  </Route>
)

export default UserRoutes