import { getSingleUserReq, toggleUserStatusReq } from "../../../redux/adminSlices/users.slice"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Fragment, useEffect } from "react"
import { grey } from "@mui/material/colors"
import moment from "moment"
import {
  Avatar, Box, Chip, CircularProgress,
  Grid2 as Grid, Skeleton, styled,
  Switch, Typography
} from "@mui/material"

const Span = styled("span")(() => ({ display: "inline-block" }))
function SingleUser() {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const { singleUser } = useSelector(state => state.usersSlice)

  const getUser = () => {
    dispatch(getSingleUserReq(userId))
  }

  const handleStatus = (e) => {
    dispatch(toggleUserStatusReq({
      id: userId,
      status: e.target.checked,
    })).unwrap().then(() => {
      getUser()
    })
  }

  useEffect(() => {
    getUser()

    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Box sx={{ p: 3, width: { xs: "100vw", laptop: "calc(100vw - 250px)" } }}>
        {!singleUser?.loading ?
          <Grid container spacing={4} sx={{ alignItems: "center" }}>
            <Grid size={{ xs: 12, sm: "auto" }}>
              <Avatar
                sx={{
                  color: "#00cada",
                  bgcolor: "#ceffff",
                  width: { xs: "75px", md: "150px" },
                  height: { xs: "75px", md: "150px" },
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: "auto" }}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  textTransform: "capitalize",
                  fontSize: { xs: "24px", md: "32px" },
                }}
                component="h2"
              >{singleUser?.fullName}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: { xs: "16px", md: "18px" },
                }}
              >{singleUser?.email}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                }}
              >Status:&nbsp;
                <Chip
                  sx={{
                    "span": {
                      color: "white",
                      lineHeight: "1",
                      fontSize: "12px",
                      overflow: "unset",
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                    },
                    py: "2px",
                    height: "auto",
                    bgcolor: !singleUser?.status ?
                      grey[500] : "#00cada"
                  }}
                  size="small"
                  component="span"
                  label={singleUser?.status ? "active" : "deactive"}
                />
              </Typography>
            </Grid>
          </Grid> :
          <Grid container spacing={4} sx={{ alignItems: "center" }}>
            <Grid size={{ xs: 12, sm: "auto" }}>
              <Skeleton
                sx={{
                  width: { xs: "75px", md: "150px" },
                  height: { xs: "75px", md: "150px" },
                }}
                variant="circular"
              />
            </Grid>
            <Grid size={{ xs: 12, sm: "auto" }} sx={{ flexGrow: "1 !important" }}>
              <Skeleton
                sx={{
                  transform: "none",
                  width: { xs: "75%", md: "250px" },
                  height: { xs: "24px", md: "32px" },
                }}
              />
              <Skeleton
                sx={{
                  mt: 1,
                  transform: "none",
                  width: { md: "350px" },
                  height: { xs: "16px", md: "18px" },
                }}
              />
            </Grid>
          </Grid>
        }

        {!singleUser?.loading ?
          <Box sx={{ py: 3 }}>
            <Typography
              sx={{
                mb: 2,
                fontFamily: "Poppins",
                fontSize: { xs: "16px", md: "18px" },
              }}
            >
              <Span sx={{ fontWeight: "500", minWidth: "150px" }}>Name : &nbsp;</Span>
              <Span sx={{ fontWeight: "400" }}>{singleUser?.fullName}</Span>
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontFamily: "Poppins",
                fontSize: { xs: "16px", md: "18px" },
              }}
            >
              <Span sx={{ fontWeight: "500", minWidth: "150px" }}>Email : &nbsp;</Span>
              <Span sx={{ fontWeight: "400" }}>{singleUser?.email}</Span>
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontFamily: "Poppins",
                fontSize: { xs: "16px", md: "18px" },
              }}
            >
              <Span sx={{ fontWeight: "500", minWidth: "150px" }}>Role : &nbsp;</Span>
              <Span sx={{ fontWeight: "400" }}>{singleUser?.role}</Span>
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontFamily: "Poppins",
                fontSize: { xs: "16px", md: "18px" },
              }}
            >
              <Span sx={{ fontWeight: "500", minWidth: "150px" }}>Joined : &nbsp;</Span>
              <Span sx={{ fontWeight: "400" }}>{moment(singleUser?.createdAt).format("DD/MM/YYYY")}</Span>
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontFamily: "Poppins",
                fontSize: { xs: "16px", md: "18px" },
              }}
            >
              <Span sx={{ fontWeight: "500", minWidth: "150px" }}>Staus : &nbsp;</Span>
              <Span sx={{ fontWeight: "400" }}>
                {!singleUser?.sLoading ?
                  <Switch
                    sx={{
                      ".MuiButtonBase-root": {
                        color: "#00cada !important"
                      },
                      ".MuiSwitch-track": {
                        bgcolor: singleUser?.status ? "#1ce7f4 !important" : ""
                      },
                    }}
                    defaultChecked={singleUser?.status}
                    onChange={handleStatus}
                  /> :
                  <CircularProgress
                    size={25}
                    thickness={6}
                    sx={{
                      color: "#00cada",
                      verticalAlign: "middle",
                      "span": { verticalAlign: "middle" }
                    }}
                  />
                }
              </Span>
            </Typography>
          </Box> :
          <Box sx={{ py: 3 }}>
            {new Array(5).fill().map((_, i) => (
              <Skeleton
                sx={{
                  mt: 1,
                  transform: "none",
                  width: { xs: "100%", md: "75%" },
                  height: { xs: "24px", md: "28px" },
                }}
                key={i}
              />
            ))
            }
          </Box>
        }
      </Box>
    </Fragment>
  )
}

export default SingleUser