import { handleSaveAdminReq } from "../../redux/userSlices/request.slice"
import { toastError } from "../../redux/guestSlices/other.slice"
import { useDispatch, useSelector } from "react-redux"
import { Fragment, useRef, useState } from "react"
import MuiIcons from "../../assets/js/muiIcons"
import { Link } from "react-router-dom"

import {
  handleContentGenerationReq,
  handleUploadFileReq
} from "../../redux/userSlices/chat.slice"

import {
  Box, Button, CircularProgress,
  Container, Grid2 as Grid, Input,
  MenuItem, Select, Skeleton,
  styled, Typography
} from "@mui/material"
import ImgWithSkel from "../../components/common/ImgWithSkel"

const Paragraph = styled("p")(() => ({}))
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
})

const options = ["Pet Waste", "Pet off-leash", "Trash", "Reserved Parking", "Handicap Parking"]

function Upload() {
  const note = useRef("")
  const fileRef = useRef()
  const dispatch = useDispatch()
  const [res, setRes] = useState("")
  const [img, setImg] = useState("")
  const [violationType, setViolationType] = useState("")
  const { file, chat } = useSelector(state => state.chatSlice)
  const { request } = useSelector(state => state.requestSlice)

  const handleUpload = (e) => {
    const fileType = e.target.files[0]?.name.split(".").pop()
    const validFiles = ["jpeg", "jpg", "png"]
    if (!validFiles?.includes(fileType)) {
      return dispatch(toastError("Invalid file type!"))
    }
    if (!options?.includes(violationType)) {
      return dispatch(toastError("Please select violation type!"))
    }

    dispatch(handleUploadFileReq({
      violationType,
      file: e.target.files[0],
    })
    ).unwrap().then((data) => {
      setImg(data?.fileURL)
      if ((
        violationType === "Pet Waste" ||
        violationType === "Pet off-leash"
      )) {
        dispatch(handleContentGenerationReq({
          violationType,
          url: data?.fileURL,
        })
        ).unwrap().then((res) => {
          setRes(res)
        })
      }
    })
    e.target.value = ""
  }

  const handleSaveReq = () => {
    dispatch(handleSaveAdminReq({
      ...file,
      violationType,
      promptResponse: res,
      note: note?.current?.value
    })).unwrap()
      .then(() => {
        setRes("")
        setImg("")
        setViolationType("")
        note.current.value = ""
      })
  }

  const handleViolationType = (e) => {
    setViolationType(e.target.value)
  }

  return (
    <Box component="section" sx={{ p: { xs: 1, md: 3 } }}>
      <Container maxWidth="xxl">
        <Grid container spacing={3} sx={{ justifyContent: "center" }}>
          <Grid size={{ xs: 12, sm: 10, md: 6 }}>
            <Box>
              <Grid container spacing={3}>
                <Grid size={12} sx={{ textAlign: "end" }}>
                  <Button
                    sx={{
                      fontSize: "16px",
                      minWidth: "150px",
                      fontWeight: "400",
                      bgcolor: "#00cada",
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                      boxShadow: "none !important"
                    }}
                    to="/user/all-request"
                    variant="contained"
                    LinkComponent={Link}
                  >View all requests
                  </Button>
                </Grid>

                <Grid size={12}>
                  <Select
                    displayEmpty
                    value={violationType}
                    onChange={handleViolationType}
                    sx={{
                      width: "100%",
                      ".MuiSelect-select": { py: 1 },
                      "fieldset": { border: "1px solid #110e24 !important" },
                    }}
                    disabled={img}
                  >
                    <MenuItem disabled value="">Violation Type</MenuItem>
                    {options?.map((option, i) => (
                      <MenuItem
                        key={i}
                        value={option}
                      >{option}
                      </MenuItem>
                    ))
                    }
                  </Select>
                </Grid>

                <Grid size={12}>
                  {img ?
                    <ImgWithSkel
                      diamention={{
                        height: "350px",
                      }}
                      src={img}
                    /> :
                    <Button
                      sx={{
                        py: 5,
                        width: "100%",
                        color: "#110e24",
                        fontSize: "28px",
                        fontWeight: "400",
                        fontFamily: "Poppins",
                        flexDirection: "column",
                        textTransform: "capitalize",
                        border: "1px dashed #110e24",
                      }}
                      component="label"
                      variant="outlined"
                      disabled={file?.loading || !options?.includes(violationType)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        {file?.loading ?
                          <CircularProgress
                            sx={{
                              mr: 1,
                              color: file?.loading ? "#00000042" : "#110e24",
                            }}
                            size={30}
                            thickness={6}
                          /> :
                          <MuiIcons.UploadFileRounded sx={{ fontSize: "32px !important", mr: 1 }} />
                        }
                        {file?.loading ?
                          "Uploading.." :
                          "Upload file"
                        }
                      </Box>
                      <Paragraph
                        sx={{
                          mt: 1,
                          opacity: "0.7",
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                        }}
                      >Supported files : JPEG, PNG, JPG
                      </Paragraph>
                      <VisuallyHiddenInput
                        type="file"
                        ref={fileRef}
                        onChange={handleUpload}
                      />
                    </Button>
                  }
                </Grid>

                <Grid size={12}>
                  <Grid size={12}>
                    <Input
                      sx={{
                        p: "4px 8px",
                        width: "100%",
                        borderRadius: "4px",
                        border: "1px solid #110e24",
                        ":after": { display: "none" },
                        ":before": { display: "none" },
                        "textarea": {
                          height: "auto",
                          color: "#110e24",
                          fontSize: "15px",
                          fontFamily: "Poppins",
                        }
                      }}
                      inputRef={note}
                      inputProps={{ rows: 4 }}
                      inputComponent="textarea"
                      placeholder="Enter a note here..."
                    />
                  </Grid>
                </Grid>

                {(violationType === "Pet Waste" || violationType === "Pet off-leash") &&
                  <Grid size={12}>
                    {chat?.loading &&
                      <Typography
                        sx={{
                          mb: 2,
                          opacity: "0.7",
                          color: "#110e24",
                          fontSize: "32px",
                          textAlign: "center",
                          fontFamily: "Poppins",
                        }}
                      >Generating Content..
                      </Typography>
                    }
                    {chat?.loading ?
                      <Fragment>
                        <Skeleton sx={{ height: "30px" }} />
                        <Skeleton sx={{ height: "30px" }} />
                        <Skeleton sx={{ height: "30px", width: "50%" }} />
                      </Fragment> :
                      res && res?.split("\n\n")?.map((text, i) => (
                        <Typography
                          sx={{
                            mt: 1,
                            opacity: "0.7",
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "Poppins",
                          }}
                          key={i}
                          variant="body2"
                        >{text}
                        </Typography>
                      ))
                    }
                  </Grid>
                }

                <Grid size={12}>
                  {(violationType && img) &&
                    <Button
                      sx={{
                        fontSize: "16px",
                        minWidth: "150px",
                        fontWeight: "400",
                        bgcolor: "#00cada",
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                      }}
                      variant="contained"
                      onClick={handleSaveReq}
                      endIcon={request?.loading &&
                        <CircularProgress
                          size={20}
                          thickness={6}
                          sx={{ color: "white" }}
                        />
                      }
                    >submit
                    </Button>
                  }
                </Grid>
              </Grid>
            </Box>
          </Grid>

        </Grid>
      </Container>
    </Box>
  )
}

export default Upload